'use client';

import { useEffect, useState } from 'react';
import { SvgArrow } from '@icons/designSystemIcons/SvgArrow';
import { SvgHeart } from '@icons/designSystemIcons/SvgHeart';
import { SvgSunGlow } from '@icons/designSystemIcons/SvgSunGlow';
import { SvgPlusSmall } from '@icons/oldIcons/SvgPlusSmall';
import { getDiscountedPrice } from '@utils/productCommon';
import ROUTES from '@utils/routes';
import Tracker from '@utils/tracker';
import { useCartStore } from 'app/(dashboard)/dashboard/(pages)/budgets/stores/userCartStore';
import { useSessionStore } from 'app/stores/globalStore';
import { CartItem, Product, ProductType } from 'app/types/product';
import { getProductCardColor, useImageProps } from 'app/utils/common';
import { Button } from 'designSystem/Buttons/Buttons';
import { Flex } from 'designSystem/Layouts/Layouts';
import { Text } from 'designSystem/Texts/Texts';
import { isEmpty } from 'lodash';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

export default function ProductCard({
  product,
  className = '',
  isDashboard,
  type,
  ...rest
}: {
  product: Product;
  className?: string;
  isDashboard?: boolean;
  type?: ProductType;
  [key: string]: any;
}) {
  const tracker = new Tracker();

  const pathName = usePathname();
  const { setBudgetId, currentOrigin, selectedClinic } = useSessionStore(
    state => state
  );
  const { imgSrc, alignmentStyles, setNextImgSrc } = useImageProps(product);
  const [discountedPrice, setDiscountedPrice] = useState<0 | number>(0);
  const { setHighlightedProduct } = useCartStore(state => state);
  const addToCart = useCartStore(state => state.addItemToCart);

  const LANDINGS: { [key: string]: string } = {
    '/landing/ppc/holaglow': '#leadForm',
  };

  const isLanding = Object.keys(LANDINGS).includes(usePathname());
  useEffect(() => {
    if (!isEmpty(product.discounts)) {
      const discountedPrice = getDiscountedPrice(
        product,
        currentOrigin,
        selectedClinic?.id
      );

      if (discountedPrice !== null) {
        setDiscountedPrice(discountedPrice);
      }
    }
  }, [product]);

  const imgHeight = isDashboard ? 'h-[200px]' : 'h-[250px]';

  const productElement = (
    <div
      className={`flex flex-col h-full overflow-hidden 
        ${product.type !== ProductType.Medical ? 'group' : 'pt-4'}
        ${[ProductType.GlowBox, ProductType.GiftCard].includes(product.type) && type === ProductType.Medical ? 'mt-[40px]' : ''}
      `}
      onClick={() => {
        if (isDashboard) setHighlightedProduct(product);
      }}
    >
      <Flex layout="col-left" className="">
        <div
          className={`relative w-full rounded-t-2xl ${product.type === ProductType.Medical ? imgHeight : 'bg-hg-black500/10 overflow-hidden aspect-[6/5]'}`}
        >
          {product.type === ProductType.Medical ? (
            <>
              <div
                className="absolute inset-0 top-[10%] rounded-t-2xl bg-gradient from-hg-pink to-hg-pink/5"
                style={{
                  background: getProductCardColor(product.cardBackgroundColor),
                }}
              />
              <Image
                alt={product.title}
                width={400}
                height={300}
                src={imgSrc}
                onError={() => setNextImgSrc()}
                className={`relative ${
                  product.productCardImagePosition != 'full'
                    ? alignmentStyles + ' ' + imgHeight
                    : 'rounded-t-2xl top-[10%]'
                } w-auto`}
              />
            </>
          ) : (
            <>
              <Image
                alt={product.title}
                src={imgSrc}
                fill
                onError={() => setNextImgSrc()}
                className="object-cover group-hover:scale-105 transition-all"
              />
              {rest.tag && (
                <span className="absolute bottom-4 left-4 bg-white/90 rounded-full px-2 py-1 text-xs ">
                  {rest.tag}
                </span>
              )}
            </>
          )}

          {!isEmpty(product.tags) &&
            product.tags[0].tag &&
            product.tags[0].tag === 'Black Friday' && (
              <Flex
                layout="row-center"
                className="bg-hg-black text-hg-secondary rounded-full p-1 px-2 absolute bottom-3 left-3"
              >
                <SvgSunGlow
                  height={12}
                  width={12}
                  className="text-hg-primary mr-1"
                />
                <Text size="xs">
                  Black
                  <span className="font-semibold font-gtUltra text-hg-secondary500">
                    Friday
                  </span>
                </Text>
              </Flex>
            )}
          {!isEmpty(product.tags) &&
            product.tags[0].tag &&
            product.tags[0].tag === 'San Valentín' && (
              <Flex
                layout="row-center"
                className="bg-hg-red text-hg-pink rounded-full py-1 px-2 absolute bottom-3 left-3"
              >
                <SvgHeart className="h-4 w-4" />
                <Text className="text-xs text-hg-skin ml-2 font-bold font-gtUltra">
                  San Valentín
                </Text>
              </Flex>
            )}
        </div>
      </Flex>
      <Flex
        layout="col-left"
        className="p-3 flex-grow bg-white rounded-b-2xl z-10"
      >
        <Text as="h2" className="mb-2 font-semibold">
          {product.title}
        </Text>
        {!isDashboard && (
          <Text size="xs" className="text-hg-black500 mb-8">
            {product.longDescription}
          </Text>
        )}
        <Flex
          layout={isDashboard ? 'col-left' : 'row-left'}
          className="mt-auto items-start justify-between w-full"
        >
          {!rest.hidePrice && (
            <Flex
              layout={isDashboard ? 'row-left' : 'col-left'}
              className={isDashboard ? 'mb-2' : ''}
            >
              {product.hasUpgrades && (
                <Text className="text-xs text-hg-black500">Desde</Text>
              )}
              {discountedPrice > 0 && (
                <Text
                  className={`text-xs line-through text-hg-black500 ${
                    isDashboard ? 'order-2 text-md' : ''
                  }`}
                >
                  {product.price} €
                </Text>
              )}

              <Text
                className={`text-hg-secondary font-semibold ${
                  isDashboard ? 'text-xl ml-1 -mt-1' : 'text-lg'
                }`}
              >
                {discountedPrice ? discountedPrice : product.price} €{' '}
              </Text>
            </Flex>
          )}

          {isDashboard ? (
            <Button
              size="sm"
              type="primary"
              className="w-full"
              onClick={e => {
                e.stopPropagation();
                addToCart(product as CartItem);
                setBudgetId('');
              }}
            >
              <p className="mr-1">Añadir </p>
              <SvgPlusSmall height={20} width={20} />
            </Button>
          ) : (
            <Button type="primary" className="ml-auto">
              <p className="mr-2">Saber más</p>
              <SvgArrow height={20} width={20} />
            </Button>
          )}
        </Flex>
      </Flex>
    </div>
  );

  if (!isDashboard) {
    return (
      <a
        id="tmevent_click_product_card"
        onClick={() =>
          tracker.track('Click', 'ProductCard', product?.extraInformation?.slug)
        }
        href={
          isLanding
            ? LANDINGS[pathName]
            : `${product.type === 2 ? ROUTES.treatments : ROUTES.aestheticTreatments}/${product?.extraInformation?.slug}`
        }
        className={`text-inherit ${className}`}
        {...rest}
      >
        {productElement}
      </a>
    );
  }

  if (isDashboard) return productElement;
}
